<template>
  <div class="activist-rating">
    <h1 class="activist-rating__title">{{ $t('rating-of-activists') }}</h1>
    <ActivistRatingContent @visibleAlert="showAlert=true"/>
    <ui-alert v-model="showAlert">
      <h2 class="ui-alert__title">{{ $t('attention') }}</h2>
      <p class="ui-alert__text">{{getTitle}}</p>
      <ui-button @click="$router.push('/login')" v-if="!isAuth">{{ $t('log-in') }}</ui-button>
      <ui-button @click="$router.push('/profile/edit-profile')" v-else>{{ $t('register') }}</ui-button>
    </ui-alert>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ActivistRating",
  components: {
    UiButton: () => import('@/components/ui/UiButton'),
    UiAlert: () => import('@/components/ui/UiAlert'),
    ActivistRatingContent: () => import('@/components/template/activist-rating/ActivistRatingContent')
  },

  data() {
    return {
      showAlert: false
    }
  },

  computed: {
    ...mapGetters(['getUser', 'isAuth']),
    getTitle () {
      return !this.isAuth ? this.$t('log-in-to-view-activist-rankings') : this.$t('sign-up-to-view-activist-rankings')
    }
  },
}
</script>

<style lang="scss" scoped>
.activist-rating {
  padding: 150px 50px 50px 50px;
  background: #F5F5F5;
  min-height: 100vh;
  display: flex;
  gap: 153px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 768px) {
    padding: 104px 20px 50px 20px;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
    white-space: nowrap;
  }
}
</style>
